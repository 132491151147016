import {
  makeResponsive,
  placeTitle,
  placeImage,
  placeInput,
  placeSlider,
  hoverMe,
  placeRec,
  hiddenPt,
  fixedPt,
  clearInputFields,
  dragMe,
  placeArrow,
  placeGravity,
  placeText,
  placeMiddleText,
  placeLine,
  placePoint,
  placeGlider,
  placeRuler,
  placeLeftText,
  placeCircle,
  placeAngle,
  placeDash,
  placeLabel,
  placeRightText,
//placePoint(board, positionX, positionY, size, cols1, cols2)
//placeDash(board, Pt1, Pt2, width, cols)
  placeArc,
  placeLogo,
  placeErase,
  placeShuffle,
  placeTest,
  placeSeg,
  setConfig,
  placeStat,
  drawTriangle,
  shuffle,
  placeEraseLeft,
  placeTapeHori,
  placeTapeVert,
  placeSq,
  placeBoldText,
  placeRedo,
  placeUndo,
  drawPoint,
  drawTri,
  setSize,
  labelIt,
  drawDash,
  placeEggs,
  createSpace,
  drawSegment,
  drawLine,
  drawArc,
  drawIntersection,
  setColor,
  drawCircle,
  setWidth
} from '../Utils';
const Boxes = {
    //JXG.Options.line.highlight=false;
    box1: function () {
    //Create the Board
    var graph =createSpace(-8, 7, -8, 7);
    graph.suspendUpdate();
    // Layering
    graph.options.axis.strokeWidth=0;
    graph.options.layer['image']=17;
    graph.options.layer['text']=16;
    graph.options.layer['line']=16;
    graph.options.layer['point']=17;
    graph.options.layer['glider']=17;
    graph.options.layer['angle']=18;
    graph.options.board.minimizeReflow = 'none';
    graph.options.point.showInfobox =false;
    graph.options.line.highlight=false;
    graph.options.image.highlight=false;
    graph.options.text.highlight=false;
    graph.options.polygon.highlight=false;
//    graph.options.text.display='internal';
    //Make the board responsive
    makeResponsive(graph);
    // Creat titleStyle
    placeLogo(graph);
    placeTitle(graph, 'Drawing Perpendicular Bisector', '');
////////////////////////////////////////////////////////////////////////////////
    var analytics = placeUndo(graph, 'left');
    analytics.setLabel('Tap to Go to Previous Step');
    analytics.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
    analytics.on('over', function () {this.label.setAttribute({visible:true});});
    analytics.on('out', function () {this.label.setAttribute({visible:false});});
    ////////////////////////////////////////////////////
    var erase = placeRedo(graph);
    erase.setLabel('Tap to Go to Next Step')
    erase.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
    erase.on('over', function () {this.label.setAttribute({visible:true});});
    erase.on('out', function () {this.label.setAttribute({visible:false});});
/////////////////////////////////////////////////////////////////////////////
    var i = 0;
    var eggs = placeEggs(graph);
    eggs[0].setAttribute({visible:()=>i>=0});
    ////////////////
    eggs[1].setAttribute({visible:()=>i>=1});
    ////////////////
    eggs[2].setAttribute({visible:()=>i>=2});
    /////////////////////////////////////////////////////////
    var PtA = drawPoint(graph, -3, 0);   //A
    setSize(graph, PtA, 3);
    labelIt(graph, PtA, 'A');
    ///////////////////////////////////////////////
    var PtB = drawPoint(graph, 3,-1);  //B
     setSize(graph, PtB, 3);
     labelIt(graph, PtB, 'B');
     /////////////////////////////////////////
    var Ln =drawLine(graph, PtA, PtB);
    var PtR = drawPoint(graph, 1, 1);    //R
     setSize(graph, PtR, 3);
     labelIt(graph, PtR, 'R');
     setColor(graph, PtR, 'red');
     PtR.setAttribute({visible:false});

    var Pt3 = drawPoint(graph, 3, 3);
     setSize(graph, Pt3, 0);
    ////////////////////
    var Pt4 = drawPoint(graph, -3, 3);
    setSize(graph, Pt4, 0);
    ////////////////////////////////////////////


    var circleA =drawCircle(graph, PtA, PtR);
    circleA.setAttribute({visible:()=>i>0, dash:1});
    setWidth(graph, circleA, 1);

    var circleB = graph.create('circle', [circleA, PtB]);
    circleB.setAttribute({visible:()=>i>1, dash:1});
    setWidth(graph, circleB, 1);

    const ptA = {
      name:'',
      size: ()=>Math.round(2*graph.canvasWidth/800.),
      color:'black',
      strokeWidth:()=>Math.ceil(4*graph.canvasWidth/800.),
      highlight:false
    };

    var Int1 =graph.create('intersection', [circleA, circleB, 0],ptA);
    Int1.setAttribute({visible:()=>i>2});
    labelIt(graph, Int1, 'C');
    Int1.label.setAttribute({offset:[0, -10]});

    var Int2 =graph.create('intersection', [circleA, circleB, 1],ptA);
    labelIt(graph, Int2, 'D');
    Int2.label.setAttribute({offset:[0, 30]});
    Int2.setAttribute({visible:()=>i>2});

    var Perp = drawDash(graph, Int1, Int2);
    Perp.setAttribute({visible:()=>i>3});

    var Int3 =graph.create('intersection', [Perp, Ln],ptA);
    labelIt(graph, Int3, 'O');
    Int3.label.setAttribute({offset:[20, 0]});
    Int3.setAttribute({visible:()=>i>3});

    /////////////////////////////////////
    function movePtR(){
    PtR.moveTo([3+Math.round(4*Math.random()), 0],100)};
    function movePtB(){
    PtB.moveTo([0.0, 3+Math.round(4*Math.random())],100)};
    ///////////////////////////////////////////////
    var txt0 =placeMiddleText(graph, 0, 5, 'You are given a line segment AB.')
    txt0.setAttribute({visible:()=>i==0});
    var txt1 =placeMiddleText(graph, 0, 5, 'Draw a circle with center at A and radius R.')
    txt1.setAttribute({visible:()=>i==1});
    var txt2 =placeMiddleText(graph, 0, 5, 'With the same radius, draw a circle with center at B.')
    txt2.setAttribute({visible:()=>i==2});
    var txt3 =placeMiddleText(graph, 0, 5, 'Both circles intersect each other at C and D');
    txt3.setAttribute({visible:()=>i==3});
    var txt4 =placeMiddleText(graph, 0, 5, 'Join points C and D. O is the midpoint of AB');
    txt4.setAttribute({visible:()=>i==4});
    ///////////////////////////
    analytics.on('down', function(){
      if(i>0)
      {
      i-=1;
      }
      else
      {
        i=0;
      }
    });
    erase.on('down', function(){
      if(i<4)
      {
      i+=1;
      }
      else
      {
        i=4;
      }
    })
///////////////////////////////////////////////////////////////////////
    graph.unsuspendUpdate();
                      },
              }
export default Boxes;
